.user-card {
  padding: 15px 10px;
  border: 1px solid #66656534;
  border-radius: 10px;
  font-size: 14px;
  margin: 10px 0;
}

.user-card .user-count {
  font-size: 24px;
  font-weight: bold;
}
.user-card p {
  margin: 0;
  color: #64748b;
}

.orders-card {
  border-radius: 10px;
  background-color: #04459d;
  color: #fff;
  padding: 16px;
}

.orders-card h3,
.orders-card .sub-title {
  font-size: 14px;
  font-weight: 200;
}

.orders-card .title {
  font-size: 24px;
  margin: 16px 0 0 0;
  font-weight: 400;
}

.revenue-card {
  margin-top: 20px;
  padding: 20px 15px;
  border: 1px solid #66656534;
  border-radius: 10px;
  font-size: 14px;
}

.revenue-card h3,
.revenue-card h4 {
  font-size: 14px;
  font-weight: 700;
}

.revenue-sub-card {
  padding: 10px 10px;
  font-size: 14px;
  margin: 7px;
}

.revenue-sub-cat {
  color: #64748b;
  margin-top: 5px;
}

.revenue-title {
  font-size: 28px;
  font-weight: 600;
}

.chart-card {
  border: 1px solid #66656534;
  padding: 20px;
  margin: 14px;
  width: 96%;
  border-radius: 10px;
}

.chart-card .chart-header {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-card h4 {
  font-size: 14px;
  font-weight: 900;
  margin: 0;
}
.popper {
  position: fixed !important;
}