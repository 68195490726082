#page-content-wrapper {
  min-width: 0;
  width: 100%;
  height: 100dvh;
  overflow-y: scroll;
}

/* .sidebar-wrapper {
  height: 100dvh;
  overflow: scroll;
} */

.page-flex {
  display: flex;
}

.sidebar.toggled {
  margin-left: -278px;
}

.sidebar {
  background-color: #fff;
  display: flex;
  z-index: 5;
  height: 100%;
  flex-direction: column;
  border: 1px solid #dad9d9;
  transition: margin-left 0.3s;
  height: 100vh;
  overflow-y: scroll;
}

.sidebar .sidebar-nav {
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
}

.logo-div {
  /* padding: 40px 0 20px 20px; */
  max-width: 275px;
}

.img-logo {
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 30px;
  margin-bottom: 30px;
  max-width: 200px;
  /* display: block; */
  /* margin: 0 auto 30px; */
}

.sidebar-nav .nav-btn {
  color: #366ab0;
  text-decoration: none;
  width: auto;
  padding: 10px 20px;
  margin: 2px 0;
  text-align: left;
  font-size: 15px;
}

.sidebar-nav .nav-btn:hover {
  color: #04459d;
}

.sidebar-nav .active,
.sidebar-nav .active:hover {
  border-radius: 6px;
  background-color: #04459d;
  color: #fff;
}

@media (max-width: 960px) {
  /* Adjust the breakpoint as needed for medium screens */
  .sidebar {
    background-color: #fff;
    position: absolute;
    z-index: 200;
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    border: 1px solid #dad9d9;
    transition: margin-left 0.3s;
  }

  .toggled-min {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background-color: #0009;
    z-index: 2;
    transition: all 0.3s;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdown .nav-btn {
  padding-left: 40px;
}

.nav-btn {
  cursor: pointer;
}

.hide-dropdown {
  visibility: hidden;
  display: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}
