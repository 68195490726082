.blog-container {
  margin: 0 30px;
}

.table {
  padding: 10px 20px;
  border: 1px solid #6665656c;
  border-radius: 10px;
  margin: 10px 0;
}

.MuiTablePagination-root * {
  margin: 0;
}

.MuiTablePagination-input {
  border: 1px solid #6665656c;
  border-radius: 5px;
  padding: 0 2px;
}

.blog-header {
  padding: 20px 0;
}

.blog-header {
  border-bottom: 1px solid #66656536;
}

.MuiPaper-root {
  box-shadow: none !important;
  /* border: 0; */
}

.blog-description h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
}

.blog-description * {
  color: black;
  margin: 0;
}

.ql-tooltip.ql-editing {
  left: 300px !important;
  top: 0 !important;
}
