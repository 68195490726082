Add these CSS classes to your Coupons.css file .percent-type {
  background-color: #1eae53;
}

.fixed-type {
  background-color: #cbda00;
}

.active-status {
  background-color: #1eae53;
  /* color: #fff; */
}

.Active {
  background-color: #1eae53;
}

.Inactive {
  background-color: grey;
  color: #fff;
}

.action-button {
  display: inline-block;
  min-width: 31px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.coupon-title {
  display: flex;
  justify-content: space-between;
}

.coupon-container {
  padding-left: 35px;
  padding-right: 35px;
}

#badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.coupon-add-btn {
  background-color: #04459d !important;
  padding: 10px 30px !important;
  border-radius: 7px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 700;
}

.bottom {
  display: flex !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  /* align-items: flex-start !important; */
  /* display: flex;
    flex-direction: row !important; */
}

.edit-btn {
  background-color: white;
  border: none;
}

.delete-btn {
  background-color: white;
  border: none;
  padding-left: 10px;
}

.MuiTablePagination-spacer {
  flex: none !important;
}
