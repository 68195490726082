/* Modal.css */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal.open {
  display: flex;
  flex-wrap: wrap;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 600px;

  /* display: block;
      padding: 24px;
      border-radius: 4px;
      box-sizing: border-box;
      overflow: auto;
      outline: 0;
      width: 100%;
      height: 100%;
      min-height: inherit;
      max-height: inherit; */
}

.modal h2 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
}

/* input[type="text"],
  select  */
.ip,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* button {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      border-radius: 4px;
    }
  
  button[type="button"] {
    background-color: #ccc;
  }
  
  button[type="button"]:hover {
    background-color: #bbb;
  }
  
  button[type="button"]:last-child {
    background-color: #007bff;
    color: white;
  }
  
  button[type="button"]:last-child:hover {
    background-color: #0056b3;
  } */

.Submit-btn {
  background-color: #04459d !important;
  padding: 10px 30px !important;
  border-radius: 7px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600;
}

.Cancel-btn {
  background-color: #fff;
  padding: 10px 30px !important;
  border-radius: 7px !important;
  border: 1px solid #04459d !important;
  color: #04459d !important;
  font-weight: 600;
  font-size: 12px !important;
}
