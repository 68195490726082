@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0009;
  z-index: 5;
}

.loading-spinner {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 7px solid #f3f3f3; /* Light grey */
  border-top: 7px solid #04459d; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  /* top: 50%;
  right: 60%; */
  left: 40%;
  top: 45%;
}
